import ListAction from 'core/actions/ListAction'
import { IDataKeys } from 'k8s/datakeys.model'
import {
  listAddonVersions,
  listClusterAddons,
  listMonitoringAddons,
  listKubevirtAddons,
  listNetworkingAddons,
  listClusterVersions,
  listEksAddons,
} from 'app/plugins/infrastructure/components/clusters/cluster-addons/new-actions'
import { listAlertRules } from 'k8s/components/monitoring/new-actions'
import { listAlerts, listTimeSeriesAlerts, listSilences } from 'k8s/components/alarms/new-actions'
import {
  listClusters,
  listSupportedRoleVersions,
  listClusterEvents,
} from 'app/plugins/infrastructure/components/clusters/newActions'
import {
  listApiGroups,
  listApiResources,
  listClusterRoleBindings,
  listClusterRoles,
  listCoreApiResources,
  listRoles,
  listRoleBindings,
} from 'k8s/components/rbac/new-actions'
import { listApps, listAppsAvailableToCluster } from 'k8s/components/app-catalog/apps/new-actions'
import {
  loadAwsRegionInfo,
  listCloudProviders,
} from 'app/plugins/infrastructure/components/cloudProviders/new-actions'
import { listPrometheusRules, listServiceAccounts } from 'k8s/components/prometheus/new-actions'
import { listStorageClasses } from 'k8s/components/storage/storage-classes/new-actions'
import { listCSIDrivers } from 'k8s/components/storage/csi-drivers/new-actions'
import { listPersistentVolumes } from 'k8s/components/storage/persistent-volume/new-actions'
import { listPersistentVolumeClaims } from 'k8s/components/storage/persistent-volume-claims/new-actions'
import { listServices } from 'k8s/components/services/new-actions'
import { listDeployedApps } from 'k8s/components/app-catalog/deployed-apps/new-actions'
import { listDeployments } from 'k8s/components/deployments/new-actions'
import {
  listImportedClusters,
  listClusterAgents,
} from 'app/plugins/infrastructure/components/importedClusters/new-actions'
import { listIngresses } from 'k8s/components/ingresses/new-actions'
import {
  listCredentials,
  listUsers,
  listUserRoleAssignments,
} from 'account/components/userManagement/users/new-actions'
import {
  listMngmGroups,
  listMngmGroupMappings,
} from 'account/components/ssoManagement/groups/new-actions'
import { listMngmRoles } from 'account/components/userManagement/roles/new-actions'
import {
  listTenants,
  listTenantRoleAssignments,
  listUserTenants,
} from 'account/components/userManagement/tenants/new-actions'
import { listNamespaces } from 'k8s/components/namespaces/new-actions'
import { listNodes, listK8sNodes } from 'app/plugins/infrastructure/components/nodes/new-actions'
import { listPodMetrics } from 'k8s/components/pods/metrics/new-actions'
import { listPods } from 'k8s/components/pods/new-actions'
import { listRbacProfileBindings, listRbacProfiles } from 'k8s/components/rbac/profiles/new-actions'
import { listRegions } from 'app/plugins/infrastructure/components/regions/actions'
import {
  listRepositories,
  listRepositoriesForCluster,
} from 'k8s/components/app-catalog/repositories/new-actions'
import { listResMgrHosts } from 'app/plugins/infrastructure/components/resMgrHosts/actions'
import { listConfigMaps } from 'k8s/components/config-maps/new-actions'
import {
  listVirtualMachines,
  listVirtualMachineInstances,
  listVirtualMachinePresets,
  listLiveMigrations,
  listIpAllocations,
} from 'app/plugins/kubevirt/components/virtual-machines/new-actions'
import { listDataVolumes } from 'k8s/components/storage/data-volumes/new-actions'
import { listCronjobs, listJobs } from 'k8s/components/cronjobs/actions'
import { listStatefulSets } from 'k8s/components/stateful-sets/actions'
import { listReplicaSets } from 'k8s/components/replica-sets/actions'
import { listDaemonSets } from 'k8s/components/daemon-sets/actions'
import { listBareMetalHosts } from 'app/plugins/metal3/actions'
import {
  listAwsAmis,
  listCapiClusters,
} from 'app/plugins/infrastructure/components/clusters/capi/actions'
import { listCapiAwsClusters } from 'app/plugins/infrastructure/components/clusters/aws/capi/actions'
import { listNodeletControlPlanes } from 'app/plugins/infrastructure/components/clusters/capi/control-plane/nodelet-control-plane/actions'
import { listAwsManagedControlPlanes } from 'app/plugins/infrastructure/components/clusters/capi/control-plane/aws-managed-control-plane/actions'
import { listCapiHosts } from './plugins/infrastructure/components/clusters/capi/details/overview/hosts/actions'
import { listCapiClusterEvents } from './plugins/infrastructure/components/clusters/capi/details/overview/events/actions'
import { listMachineDeployments } from './plugins/infrastructure/components/clusters/capi/machine-deployment/actions'
import { listMachinePools } from './plugins/infrastructure/components/clusters/capi/machine-pool/actions'
import { listAwsManagedMachinePools } from './plugins/infrastructure/components/clusters/capi/aws-managed-machine-pool/actions'
import { listAwsMachinePools } from './plugins/infrastructure/components/clusters/capi/aws-machine-pool/actions'
import {
  listEksConfigs,
  listEksConfigTemplates,
  listNodeletConfigs,
  listNodeletConfigTemplates,
} from './plugins/infrastructure/components/clusters/capi/configs/actions'
import { listAwsMachineTemplates } from './plugins/infrastructure/components/clusters/capi/aws-machine-templates/actions'
import {
  listAwsClusterRoleIdentities,
  listAwsClusterStaticIdentities,
} from './plugins/infrastructure/components/clusters/capi/identities/actions'
import { listClusterUpgradeJobs } from './plugins/infrastructure/components/clusters/capi/upgrade/actions'
import { listEksVersions } from './plugins/infrastructure/components/versions/eks/actions'
import { listEksAmiTypes } from './plugins/infrastructure/components/clusters/capi/eks-ami-types/actions'
import {
  listInstanceTypes,
  listClusterInstanceTypes,
} from './plugins/kubevirt/components/virtual-machines/instance-types/actions'
import { listNetworkPlugins } from 'k8s/components/networking/plugins/actions'
import { listNetworks } from 'k8s/components/networking/network/actions'
import { listHostNetworkTemplates } from 'k8s/components/networking/host-configs/actions'
import { listSecrets } from 'k8s/components/configuration/secrets/actions'
import { listResourceQuotas } from 'k8s/components/configuration/resource-quotas/actions'
import { listLimitRanges } from 'k8s/components/configuration/limit-ranges/actions'
import { listHorizontalPodAutoscalers } from 'k8s/components/configuration/horizontal-pod-autoscaler/actions'
import { listPodDisruptionBudgets } from 'k8s/components/configuration/pod-disruption-budgets/actions'
import { listK8sEndpoints } from 'k8s/components/network/endpoints/actions'
import { listNetworkPolicies } from 'k8s/components/network/network-policies/actions'
import {
  listCustomResourceDefinitions,
  listCustomResources,
} from 'k8s/components/cluster-administration/custom-resource-definitions/actions'

const listActionsByKey: Record<keyof IDataKeys, ListAction<keyof IDataKeys>> = {
  AddonVersions: listAddonVersions,
  AlertRules: listAlertRules,
  Alerts: listAlerts,
  AlertsTimeSeries: listTimeSeriesAlerts,
  AllClusters: listClusters,
  ApiEndpoints: null, // @todo
  ApiGroups: listApiGroups,
  ApiResources: listApiResources,
  AppDetails: null, // @todo
  Apps: listApps,
  AppsAvailableToCluster: listAppsAvailableToCluster,
  AppVersions: null,
  CloudProviderDetails: null, // @todo
  CloudProviderRegionDetails: null, // @todo
  CloudProviders: listCloudProviders,
  ClusterAddons: listClusterAddons,
  ClusterRoleBindings: listClusterRoleBindings,
  ClusterRoles: listClusterRoles,
  Endpoints: null,
  PrometheusInstances: null, // @todo
  ServiceAccounts: listServiceAccounts,
  PrometheusRules: listPrometheusRules,
  PrometheusServiceMonitors: null, // @todo
  PrometheusAlertManagers: null, // @todo
  StorageClasses: listStorageClasses,
  CSIDrivers: listCSIDrivers,
  PersistentVolumes: listPersistentVolumes,
  PersistentVolumeClaims: listPersistentVolumeClaims,
  Silences: listSilences,
  Ssh: null, // @todo
  ServiceCatalog: null, // @todo
  Clusters: listClusters,
  CoreApiResources: listCoreApiResources,
  DeployedAppDetails: null, // @todo
  DeployedApps: listDeployedApps,
  Deployments: listDeployments,
  Flavors: null, // @todo
  ImportedClusters: listImportedClusters,
  Ingresses: listIngresses,
  KubeRoles: listRoles,
  KubeServiceDetails: null, // @todo
  KubeServices: listServices,
  Services: listServices,
  ManagementCredentials: listCredentials,
  ManagementGroups: listMngmGroups,
  ManagementGroupsMappings: listMngmGroupMappings,
  ManagementRoles: listMngmRoles,
  ManagementTenants: listTenants,
  ManagementTenantsRoleAssignments: listTenantRoleAssignments,
  ManagementUsers: listUsers,
  ManagementUsersRoleAssignments: listUserRoleAssignments,
  Namespaces: listNamespaces,
  Nodes: listNodes,
  K8sNodes: listK8sNodes,
  PodMetrics: listPodMetrics,
  PodTemplates: null,
  Pods: listPods,
  RbacProfileBindings: listRbacProfileBindings,
  RbacProfiles: listRbacProfiles,
  Regions: listRegions,
  Repositories: listRepositories,
  RepositoriesForCluster: listRepositoriesForCluster,
  ResMgrHosts: listResMgrHosts,
  RoleBindings: listRoleBindings,
  SupportedRoleVersions: listSupportedRoleVersions,
  EksVersions: listEksVersions,
  AwsRegionInfo: loadAwsRegionInfo,
  Tenants: listTenants,
  UserTenants: listUserTenants,
  ConfigMaps: listConfigMaps,
  Users: listUsers,
  VirtualMachineDetails: null, // @todo
  VirtualMachines: listVirtualMachines,
  VirtualMachineInstances: listVirtualMachineInstances,
  VirtualMachinePresets: listVirtualMachinePresets,
  Networks: listNetworks,
  DataVolumes: listDataVolumes,
  Events: listClusterEvents,
  CapiEvents: listCapiClusterEvents,
  Cronjobs: listCronjobs,
  Jobs: listJobs,
  StatefulSets: listStatefulSets,
  ReplicaSets: listReplicaSets,
  DaemonSets: listDaemonSets,
  MonitoringAddons: listMonitoringAddons,
  KubevirtAddons: listKubevirtAddons,
  NetworkingAddons: listNetworkingAddons,
  ClusterAgents: listClusterAgents,
  BareMetalHosts: listBareMetalHosts,
  CapiClusters: listCapiClusters,
  CapiAwsClusters: listCapiAwsClusters,
  NodeletControlPlanes: listNodeletControlPlanes,
  AwsAmis: listAwsAmis,
  AwsManagedControlPlanes: listAwsManagedControlPlanes,
  CapiHosts: listCapiHosts,
  AwsMachineTemplates: listAwsMachineTemplates,
  MachineDeployments: listMachineDeployments,
  MachinePools: listMachinePools,
  AwsMachinePools: listAwsMachinePools,
  AwsManagedMachinePools: listAwsManagedMachinePools,
  EksConfigs: listEksConfigs,
  EksConfigTemplates: listEksConfigTemplates,
  NodeletConfigs: listNodeletConfigs,
  NodeletConfigTemplates: listNodeletConfigTemplates,
  AwsClusterRoleIdentities: listAwsClusterRoleIdentities,
  AwsClusterStaticIdentities: listAwsClusterStaticIdentities,
  ClusterVersions: listClusterVersions,
  LiveMigrations: listLiveMigrations,
  ClusterUpgradeJobs: listClusterUpgradeJobs,
  EksAmiTypes: listEksAmiTypes,
  InstanceTypes: listInstanceTypes,
  ClusterInstanceTypes: listClusterInstanceTypes,
  EksAddons: listEksAddons,
  IpAllocations: listIpAllocations,
  NetworkPlugins: listNetworkPlugins,
  HostNetworkTemplates: listHostNetworkTemplates,
  Secrets: listSecrets,
  ResourceQuotas: listResourceQuotas,
  LimitRanges: listLimitRanges,
  HorizontalPodAutoscalers: listHorizontalPodAutoscalers,
  PodDisruptionBudgets: listPodDisruptionBudgets,
  K8sEndpoints: listK8sEndpoints,
  NetworkPolicies: listNetworkPolicies,
  CustomResourceDefinitions: listCustomResourceDefinitions,
  CustomResources: listCustomResources,
}

export default listActionsByKey
